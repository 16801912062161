import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ManualEngineAllocationRequestStatus } from '../../cash-requests/enums';
import { MarketOrderStatus } from '../../market-orders/enums';
import { DividendStatus } from '../../dividends/enums';

@Component({
    selector: 'app-status-tag',
    template: ` <p-tag class="uppercase text-sm" [severity]="severity" [value]="status!" /> `,
})
export class StatusTagComponent implements OnChanges {
    @Input() status: ManualEngineAllocationRequestStatus | MarketOrderStatus | DividendStatus | null = null;
    severity: 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['status'] && changes['status'].currentValue !== changes['status'].previousValue) {
            this.severity = this.mapOrderStatusToSeverity(this.status);
        }
    }
    mapOrderStatusToSeverity(
        orderStatus: ManualEngineAllocationRequestStatus | DividendStatus | MarketOrderStatus | null,
    ): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (orderStatus) {
            case ManualEngineAllocationRequestStatus.COMPLETED:
                return 'contrast';
            case ManualEngineAllocationRequestStatus.COMPLETED_PENDING_CONFIRMATION:
                return 'warning';
            case ManualEngineAllocationRequestStatus.STARTED:
                return 'info';
            case ManualEngineAllocationRequestStatus.STARTED_PENDING_CONFIRMATION:
                return 'warning';
            case ManualEngineAllocationRequestStatus.CREATED:
                return 'success';
            case MarketOrderStatus.COMPLETED:
                return 'contrast';
            case MarketOrderStatus.COMPLETED_PENDING_CONFIRMATION:
                return 'warning';
            case MarketOrderStatus.STARTED:
                return 'info';
            case MarketOrderStatus.STARTED_PENDING_CONFIRMATION:
                return 'warning';
            case MarketOrderStatus.CREATED:
                return 'success';
            case MarketOrderStatus.PROCESSED:
                return 'info';
            case MarketOrderStatus.PROCESSED_PENDING_CONFIRMATION:
                return 'warning';
            case MarketOrderStatus.FAILED:
                return 'danger';
            case MarketOrderStatus.FAILED_PENDING_CONFIRMATION:
                return 'danger';
            case DividendStatus.CONFIRMED:
                return 'contrast';
            case DividendStatus.PENDING_CONFIRMATION:
                return 'warning';

            default:
                return undefined;
        }
    }
}
