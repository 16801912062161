import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginRequest, LoginRequestWithGoogle, LoginResponse, RequestLoginOtpRequest } from '../shared/schemas';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { LocalStorageService } from '../shared/local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isLoggedIn = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly http: HttpClient,
        private localStorageService: LocalStorageService,
    ) {}

    requestLoginOtp(body: RequestLoginOtpRequest) {
        return this.http.post<string>(`${environment.baseUrl}/admin/auth/otp`, body);
    }

    loginUser(body: LoginRequest, otp: string, phone: string) {
        const headers = { 'x-engine-otp': otp, 'x-engine-phone': phone };
        return this.http.post<LoginResponse>(`${environment.baseUrl}/admin/auth/login`, body, { headers });
    }

    loginInWithGoogle(body: LoginRequestWithGoogle) {
        return this.http.post<LoginResponse>(`${environment.baseUrl}/admin/auth/login_with_google`, body);
    }

    logoutUser() {
        return this.http.post<LoginResponse>(`${environment.baseUrl}/admin/auth/logout`, {});
    }

    refreshToken(refreshToken: string) {
        const headers = { Authorization: `Bearer ${refreshToken}` };
        return this.http.post<LoginResponse>(`${environment.baseUrl}/admin/auth/refresh`, {}, { headers });
    }

    decodeAndParseJWTToken(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(''),
        );

        return JSON.parse(jsonPayload);
    }

    setUserContext(accessToken: string) {
        const parsed = this.decodeAndParseJWTToken(accessToken);
        Sentry.setUser({ id: parsed?.sub });
    }

    isJwtExpired(accessToken: string, buffer: number) {
        const bufferInMili = buffer * 1000;
        const parsed = this.decodeAndParseJWTToken(accessToken);
        const expiryInMillis = (parsed?.exp || 0) * 1000;
        return expiryInMillis - bufferInMili < new Date().getTime();
    }
}
