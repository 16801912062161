import { Injectable } from '@angular/core';
import { LoginResponse } from '../schemas';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private readonly accessTokenKey = 'accessToken';
    private readonly refreshTokenKey = 'refreshToken';
    constructor() {}

    setTokens(response: LoginResponse) {
        localStorage.setItem(this.accessTokenKey, response.access_token);
        localStorage.setItem(this.refreshTokenKey, response.refresh_token);
    }

    clearTokens() {
        localStorage.removeItem(this.accessTokenKey);
        localStorage.removeItem(this.refreshTokenKey);
    }

    getAccessToken() {
        return localStorage.getItem(this.accessTokenKey);
    }

    getRefreshToken() {
        return localStorage.getItem(this.refreshTokenKey);
    }
}
